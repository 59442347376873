import React, { FC } from 'react';
import SEO from '@/common/ui/organism/SEO';
import { Helmet } from 'react-helmet';
import appleTouchIcon from '@/common/ui/img/apple-touch-icon.png';

type Props = {
  canonical?: string;
}
const Head: FC<Props> = ({ canonical }) => (
  <>
    <Helmet>
      <meta
        name="format-detection"
        content="telephone=no, address=no, email=no"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
      />
      <link rel="apple-touch-icon" href={appleTouchIcon} />
      <link href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" rel="stylesheet" />
      {/* gatsby-plugin-react-helmet により設定される canonical url の上書き */}
      {canonical && <link rel="canonical" href={canonical} />}
      <script src="https://carmo-kun.jp/lp/assets/modules/index.js" type="module" defer />
    </Helmet>
    <SEO />
  </>
);

export default Head;
