import React, { FC } from 'react';
import '@/newcar/ui/common/style/main.styl';
import Head from '@/common/ui/organism/Head';

type Props = {
  canonical?: string;
}
const Layout: FC<Props> = ({ children, canonical }) => (
  <>
    <Head canonical={canonical} />
    {children}
  </>
);

export default Layout;
