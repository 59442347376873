import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

export interface Props {
  title?: string
  description?: string
  canonical?: string
  ogTitle?: string
  ogDescription?: string
  ogUrl?: string
  ogImage?: string
  ogSiteName?: string
  ogLocale?: string
  ogType?: string
  fbAppId?: string
  twitterCard?: string
  twitterTitle?: string
  twitterDescription?: string
  twitterImage?: string
}

const SEO: FC<Props> = ({
  title,
  description,
  canonical,
  ogTitle,
  ogDescription,
  ogUrl,
  ogImage,
  ogSiteName,
  ogLocale,
  ogType,
  fbAppId,
  twitterCard,
  twitterTitle,
  twitterDescription,
  twitterImage,
}) => {
  const getSEOQuery = useStaticQuery<GatsbyTypes.getSeoSiteMetaDataQuery>(graphql`
    query getSeoSiteMetaData {
      site {
        siteMetadata {
          title
          description
          ogp {
            ogTitle
            ogDescription
            ogUrl
            ogImage
            ogSiteName
            ogLocale
            ogType
            fbAppId
            twitterCard
            twitterTitle
            twitterDescription
            twitterImage
          }
        }
      }
    }
  `);
  const siteMetadata = getSEOQuery.site?.siteMetadata;

  return (
    <Helmet
      title={title ?? siteMetadata?.title ?? ''}
      link={[
        ...canonical ? [{ rel: 'canonical', key: canonical, href: canonical }] : [],
      ]}
    >
      <meta name="description" content={description ?? siteMetadata?.description ?? ''} />

      {/* OGP */}
      <meta property="og:title" name="og:title" content={ogTitle ?? siteMetadata?.ogp?.ogTitle ?? ''} />
      <meta property="og:description" name="og:description" content={ogDescription ?? siteMetadata?.ogp?.ogDescription ?? ''} />
      <meta property="og:url" name="og:url" content={ogUrl ?? siteMetadata?.ogp?.ogUrl ?? ''} />
      <meta property="og:image" name="og:image" content={ogImage ?? siteMetadata?.ogp?.ogImage ?? ''} />
      <meta property="og:site_name" name="og:site_name" content={ogSiteName ?? siteMetadata?.ogp?.ogSiteName ?? ''} />
      <meta property="og:locale" name="og:locale" content={ogLocale ?? siteMetadata?.ogp?.ogLocale ?? ''} />
      <meta property="og:type" name="og:type" content={ogType ?? siteMetadata?.ogp?.ogType ?? ''} />
      <meta property="fb:app_id" name="fb:app_id" content={fbAppId ?? siteMetadata?.ogp?.fbAppId ?? ''} />
      <meta property="twitter:card" name="twitter:card" content={twitterCard ?? siteMetadata?.ogp?.twitterCard ?? ''} />
      <meta property="twitter:title" name="twitter:title" content={twitterTitle ?? siteMetadata?.ogp?.twitterTitle ?? ''} />
      <meta property="twitter:description" name="twitter:description" content={twitterDescription ?? siteMetadata?.ogp?.twitterDescription ?? ''} />
      <meta property="twitter:image" name="twitter:image" content={twitterImage ?? siteMetadata?.ogp?.twitterImage ?? ''} />
      {/* /OGP */}
    </Helmet>
  );
};

export default SEO;
